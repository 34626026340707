import { Flex, Heading, Text, Image, Accordion, AccordionItem } from '@chakra-ui/react'

import { s3ImageURL } from '@miimosa/common'

import { QuestionItem } from '@components/common'
import { SolsVivantsItems } from '@components/purina/data/SolsVivantsItems'

const NestleSengage = () => {
  return (
    <Text mb={8}>
      Nestlé s’est engagé en 2018 dans le programme Sols Vivants, piloté par la Fondation Earthworm. Nestlé, au travers
      de Sols Vivants, vise à développer l’adoption de pratiques d’agriculture régénératrice, en fournissant un support
      technique et financier aux agriculteurs dans leur transition. L’Agriculture Régénératrice est un ensemble de
      pratiques agricoles plus durables qui permet d’améliorer la fertilité des sols agricoles et de favoriser la
      biodiversité.
    </Text>
  )
}

export const LeProgrammeSolsVivants = () => {
  return (
    <Flex width="full" bgColor="white" alignItems="center" direction="column" mb={12} maxW="1200px">
      <Flex direction="column" alignItems="center" p={{ base: 6, md: 12 }} bg="brand_white">
        <Flex direction={{ base: 'column', md: 'row' }}>
          <Flex w={{ base: 'full', md: '70%' }} direction="column" p={2}>
            <Heading mb={8} textAlign={{ base: 'center', md: 'left' }}>
              Le programme sols vivants
            </Heading>

            <NestleSengage />

            <Text mb={4}>Ces pratiques agricoles comprennent :</Text>

            <Flex direction="row" w="full" mb={8}>
              <Accordion
                allowToggle
                display="flex"
                w="full"
                h="full"
                alignItems="flex-start"
                justifyContent="left"
                flexDirection={{ base: 'column', md: 'row' }}
                columnGap={10}
                rowGap={4}
                flexWrap="wrap"
              >
                {SolsVivantsItems.map(({ title, bgColor, textColor, text }, i) => {
                  return (
                    <AccordionItem
                      key={`${title}_${i}`}
                      w={{ base: 'full', md: '300px' }}
                      maxW={{ base: 'full', md: '300px' }}
                    >
                      {({ isExpanded }) => (
                        <QuestionItem
                          title={title}
                          text={text}
                          textColor={textColor}
                          bgColor={bgColor}
                          isExpanded={isExpanded}
                          buttonH={'20px'}
                          border="none"
                          borderRadius="15px"
                        />
                      )}
                    </AccordionItem>
                  )
                })}
              </Accordion>
            </Flex>
          </Flex>

          <Flex direction="column" alignItems="center">
            <Image src={s3ImageURL('purina-sols-vivants.png')} alt={''} mb={8} />
            <Text>En partenariat avec: </Text>
            <Image src={s3ImageURL('purina-arthworm.png')} w="150px" alt={''} />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
