import { Text } from '@chakra-ui/react'

export const SolsVivantsItems = [
  {
    title: 'La réduction du travail des sols',
    bgColor: 'brand_light_green',
    textColor: 'black',
    text: (
      <>
        <Text>Notamment le labour. Le projet d’Aurélien s’inscrit dans cette démarche.</Text>
      </>
    ),
  },
  {
    title: 'La diversification des cultures',
    bgColor: 'brand_yellow',
    textColor: 'black',
    text: (
      <>
        <Text>
          La diversification des cultures vise à cultiver un maximum d’espèces différentes en alternance dans les
          champs.
        </Text>
      </>
    ),
  },
  {
    title: 'Les couverts végétaux',
    bgColor: 'brand_orange',
    textColor: 'black',
    text: (
      <>
        <Text>
          La mise en place de couverts végétaux entre les cultures de rente afin de ne pas laisser les sols à nu et
          produire de la matière organique pour les alimenter.
        </Text>
      </>
    ),
  },
  {
    title: 'L’agroforesterie',
    bgColor: 'brand_brown',
    textColor: 'black',
    text: (
      <>
        <Text>
          L’agroforesterie qui consiste à remettre l’arbre au cœur du système agricole. Celui-ci ayant de nombreux
          avantages agronomiques et écologiques.
        </Text>
      </>
    ),
  },
]
