import { Flex, Heading, Button, Text, Image, useBreakpointValue, Box } from '@chakra-ui/react'
import { s3ImageURL } from '@miimosa/common'
import React from 'react'

export const FriskiesSengage2 = () => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })
  return (
    <Flex width="full" bgColor="white" alignItems="center" direction="column" mb={12}>
      <Box as="span" id="demarche" visibility="hidden" height="0" transform="translateY(-80px)" />
      <Heading textAlign="center" marginBottom="40px">
        FRISKIES<sup style={{ fontSize: 16, verticalAlign: 'super' }}>®</sup>, une marque historique
        {!isMobile ? ' qui s’engage' : ''}
      </Heading>

      <Flex width="100%" direction={{ base: 'column', md: 'row' }} alignItems="center" mb={8}>
        <Flex
          zIndex="0"
          position="relative"
          width={{ base: '100%', md: '70%' }}
          height={{ base: '300px', md: '500px' }}
          mr={{ base: 0, md: -24 }}
          ml={{ base: 0, md: 12 }}
          mb={4}
          rounded={'xl'}
        >
          <Image
            src={s3ImageURL('purina-croquettes.png')}
            alt={''}
            objectFit="cover"
            h="full"
            w="full"
            borderRadius={{ base: '0', md: '30px' }}
          />
        </Flex>
        <Flex direction="column" width={{ base: '90%', md: '60%' }} alignItems="center" mt={{ base: -12, md: 0 }}>
          <Flex zIndex="1" borderRadius="30px" mb={4}>
            <Flex bg="white" p={{ base: 2, md: 4 }} borderRadius="30px">
              <Flex bg="brand_very_light_green" borderRadius="30px" p={4} direction="column" lineHeight={6}>
                <Text mb={4}>
                  Avec plus de 50 ans de présence sur le territoire français, FRISKIES® va encore plus loin dans son
                  engagement.
                  <br />
                  <br />
                  En 2023, 95% des croquettes FRISKIES® sont fabriquées en France à l’usine de Marconnelle. Anne-Sophie,
                  Hervé et Aurélien font partie des agriculteurs du programme Sols Vivants qui fournissent l’usine en
                  blé.
                  <br />
                  <br />
                  En 2024, nous voulons aller plus loin dans notre démarche en soutenant les agriculteurs dans leur
                  projet d’investissement dans cette transition vers des méthodes d’agriculture plus durable.
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Button as="a" href="#agriculteurs">
        Je vote pour mon projet préféré
      </Button>
    </Flex>
  )
}
