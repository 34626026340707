import { Flex, Text, Box, useBreakpointValue } from '@chakra-ui/react'
import { s3ImageURL } from '@miimosa/common'
import { PositionProps } from '@chakra-ui/styled-system'

function ImageGalleryTag({
  text,
  position = 'absolute',
  left = 'none',
  top = 'none',
}: {
  text: string
  position?: PositionProps['position']
  left?: string
  top?: string
}) {
  return (
    <Flex
      w="fit-content"
      h="fit-content"
      backgroundColor="rgba(255, 255, 255, 0.5)"
      borderRadius="9px"
      px={2}
      py={1}
      m={8}
      position={position}
      left={left}
      top={top}
    >
      <Text fontSize={18} fontWeight={700}>
        {text}
      </Text>
    </Flex>
  )
}

function ImageGalleryDesktop() {
  return (
    <Flex position="relative" width="100%" height="400px">
      <Box
        width="50%"
        height="100%"
        overflow="hidden"
        clipPath="polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%)"
        ml="-4%"
        bgImage={s3ImageURL('purina-herve-chien.jpeg')}
        bgPosition="0 20%"
        bgSize="cover"
      >
        <ImageGalleryTag text="Hervé Mesnard" position="relative" left="10%" />
      </Box>
      <Box
        width="50%"
        height="100%"
        overflow="hidden"
        clipPath="polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%)"
        ml="-4%"
        bgImage={s3ImageURL('purina-anne-sophie-chien.jpeg')}
        bgPosition="0 20%"
        bgSize="cover"
        justifyContent="center"
        alignItems="flex-end"
        display="flex"
      >
        <ImageGalleryTag text="Anne-Sophie Niquet" />
      </Box>
      <Box
        width="50%"
        height="100%"
        overflow="hidden"
        clipPath="polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%)"
        ml="-4%"
        bgImage={s3ImageURL('purina-aurelien-chat.jpeg')}
        bgPosition="0 20%"
        bgSize="cover"
      >
        <ImageGalleryTag text="Aurélien Crequy" position="relative" left="65%" />
      </Box>
    </Flex>
  )
}

function ImageGalleryMobile() {
  return (
    <Flex position="relative" width="100%" direction="column">
      <Box
        width="100%"
        height="400px"
        overflow="hidden"
        bgImage={s3ImageURL('purina-herve-chien.jpeg')}
        bgPosition="20% 20%"
        bgSize="cover"
        clipPath="polygon(0% 0%, 100% 0%, 100% 90%, 0% 100%)"
      >
        <ImageGalleryTag text="Hervé Mesnard" />
      </Box>
      <Box
        width="100%"
        height="400px"
        overflow="hidden"
        bgImage={s3ImageURL('purina-anne-sophie-chien.jpeg')}
        bgPosition="20% 20%"
        bgSize="cover"
        clipPath="polygon(0% 10%, 100% 0%, 100% 90%, 0% 100%)"
        mt="-30px"
      >
        <ImageGalleryTag text="Anne-Sophie Niquet" position="relative" left="15%" top="70%" />
      </Box>
      <Box
        width="100%"
        height="400px"
        overflow="hidden"
        bgImage={s3ImageURL('purina-aurelien-chat.jpeg')}
        bgPosition="20% 20%"
        bgSize="cover"
        clipPath="polygon(0% 10%, 100% 0%, 100% 100%, 0% 100%)"
        mt="-30px"
      >
        <ImageGalleryTag text="Aurélien Crequy" position="relative" top="45%" />
      </Box>
    </Flex>
  )
}

export const FriskiesSengage = () => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })
  return (
    <Flex width="full" bgColor="white" alignItems="center" justifyContent="center" direction="column" mb={12}>
      {isMobile ? <ImageGalleryMobile /> : <ImageGalleryDesktop />}
    </Flex>
  )
}
