import { Flex, Center, Button, Image } from '@chakra-ui/react'

import { Icon, Link } from '@miimosa/design-system'
import { s3ImageURL } from '@miimosa/common'

export const Footer = () => {
  return (
    <Flex direction="column" w="full" bg="brand_orange" h="auto" px={{ base: 8, md: 16 }} py={10} alignItems={'center'}>
      <Flex w="full" direction={{ base: 'column', md: 'row' }} rowGap={10}>
        <Flex w="full" direction="column" rowGap={4} justifyContent="center" alignItems="center" color="white">
          <Link href="https://www.miimosa.com" isExternal>
            <Icon name={'Logo2'} size="2xl" h="auto" />
          </Link>
          <Flex direction="row" columnGap={2}>
            <Link isExternal href="https://www.instagram.com/MiiMOSA_fr/">
              <Icon name="Instagram" size="4xs" h="auto" />
            </Link>
            <Link isExternal href="https://www.facebook.com/MiiMOSAfr">
              <Icon name="Facebook" size="4xs" />
            </Link>
            <Link isExternal href="https://www.linkedin.com/company/miimosa/">
              <Icon name="LinkedIn" size="4xs" h="auto" />
            </Link>
          </Flex>
          <Link href="https://blog.miimosa.com/" isExternal>
            <Button
              fontWeight="normal"
              bg="brand_orange"
              border="solid 1px white"
              width="120px"
              height="30px"
              borderRadius={0}
              justifyContent="flex-start"
            >
              <Center fontSize="sm" color="white" h="100%" w="100%">
                Blog MiiMOSA
              </Center>
            </Button>
          </Link>
        </Flex>
        <Flex w="full" direction="column" rowGap={6} justifyContent="center" alignItems="center">
          <Flex direction="column" justifyContent="center" alignItems="center">
            <Flex as={'a'} href="https://www.purina.fr/marques/friskies/chien">
              <Image src={s3ImageURL('purina-logo-friskies.png')} width="100" height="100" alt={''} />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
