import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'

export const CommentCaMarche = () => {
  return (
    <>
      <Flex
        width={{ base: 'full', md: '80%' }}
        height="fit-content"
        bgColor="brand_white"
        borderRadius={{ base: 'none', md: '16px' }}
        p={12}
        alignItems="center"
        direction={{ base: 'column', md: 'row' }}
        mb={{ base: 0, md: 12 }}
        maxW="1000px"
      >
        <Flex w="full" direction="column" mr={8} textAlign={{ base: 'center', md: 'left' }}>
          <Box as="span" id="comment_ca_marche" visibility="hidden" height="0" transform="translateY(-80px)" />
          <Text>
            FRISKIES® participe au financement de 3 projets d’agriculture régénératrice avec une enveloppe totale de
            30&nbsp;000€.
            <br />
            <br />
            Chaque projet agricole est assuré de recevoir 5&nbsp;000€.
            <br />
            Les 15&nbsp;000€ restants sont réparti au prorata des votes en ligne entre les trois projets.
            <br />
            <br />
            Votre voix compte,{' '}
            <Text as="span" fontWeight="bold">
              votez pour votre projet préféré !
            </Text>
          </Text>
        </Flex>
      </Flex>
    </>
  )
}
