import { Flex, Heading, Button, Text, Image, Box } from '@chakra-ui/react'
import { s3ImageURL } from '@miimosa/common'
import React from 'react'

export const LesEngagementsPurina = () => {
  return (
    <Flex width="full" bgColor="white" alignItems="center" direction="column" mb={12}>
      <Box as="span" id="engagements" visibility="hidden" height="0" transform="translateY(-80px)" />

      <Heading textAlign="center" marginBottom="40px" color="brand_red">
        Les engagements Purina<sup style={{ fontSize: 16, verticalAlign: 'super' }}>®</sup>
      </Heading>

      <Flex
        width={{ base: '100%', md: '80%' }}
        direction={{ base: 'column', md: 'row' }}
        alignItems="center"
        mb={8}
        justifyContent="center"
      >
        <Flex zIndex="0" position="relative" mr={{ base: 0, md: -24 }} w="100%" ml={{ base: 0, md: 12 }} mb={4}>
          <Image
            src={s3ImageURL('purina-grains.jpeg')}
            alt={''}
            borderRadius={{ base: '0', md: '30px' }}
            h={{ base: '600px', md: '800px' }}
            style={{ objectFit: 'cover' }}
            objectPosition="left"
          />
          <Image
            src={s3ImageURL('purina-logo.png')}
            position="absolute"
            top="0"
            right="0"
            mt="30px"
            mr="30px"
            w="30%"
          />
        </Flex>
        <Flex zIndex="1" borderRadius="30px" w="90%" mt={{ base: -24, md: 0 }}>
          <Flex bg="white" p={{ base: 2, md: 4 }} borderRadius="30px">
            <Flex bg="brand_white" borderRadius="30px" p={{ base: 4, md: 8 }} direction="column" lineHeight={8}>
              <Text fontSize={16} mb={4} lineHeight={6}>
                Chez PURINA®, nous sommes fiers d’être le premier intervenant du petfood à avoir rejoint le programme
                Sols Vivants aux côtés de la fondation Earthworm pour soutenir les agriculteurs dans leur transition
                vers des pratiques agricoles régénératrices.
                <br />
                <br />
                En 2023, ils étaient plus de 250 à fournir du blé et du maïs pour nos usines françaises. Nous
                ambitionnons que d’ici 2025, 20% des céréales et des protéines végétales de nos produits proviennent de
                pratiques agricoles régénératrices, et 50% en 2030.
                <br />
                <br />
                Nous sommes très attachés à notre ancrage local : nous privilégions des approvisionnements locaux
                lorsque nous le pouvons et fabriquons dans nos 5 usines françaises 80% des volumes vendus en France en
                2023.
              </Text>
              <Button w="fit-content" bgColor="brand_red">
                En savoir plus
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
